

// ----------------------------------------------------------------------

export const navConfig = [
  // {
  //   title: 'Home',
  //   icon: <Iconify icon="ion:home-outline" />,
  //   path: '/',
  // },
  // {
  //   title: 'Blog',
  //   icon: <Iconify icon="carbon:blog" />,
  //   path: paths.blog.root,
  // },
  // {
  //   title: 'Pricing',
  //   icon: <Iconify icon="solar:hand-money-outline" />,
  //   path: paths.pricing,
  // },

];
