import { Box } from "@mui/material";
import Stack from '@mui/material/Stack';
import AppBar from '@mui/material/AppBar';
import Button from '@mui/material/Button';
import Toolbar from '@mui/material/Toolbar';
import { useTheme } from '@mui/material/styles';
import Container from '@mui/material/Container';
import Badge, { badgeClasses } from '@mui/material/Badge';

import { useOffSetTop } from 'src/hooks/use-off-set-top';
import { useResponsive } from 'src/hooks/use-responsive';

import { bgBlur } from 'src/theme/css';

import Logo from 'src/components/logo';

import NavMobile from './nav/mobile';
import NavDesktop from './nav/desktop';
import { HEADER } from '../config-layout';
import { navConfig } from './config-navigation';
import HeaderShadow from '../common/header-shadow';
// import { useAuthContext } from "../../auth/hooks/use-auth-context";

// ----------------------------------------------------------------------

export default function Header () {
  const theme = useTheme();
  const mdUp = useResponsive('up', 'md');

  // const { user, logout } = useAuthContext();
  const offsetTop = useOffSetTop(HEADER.H_DESKTOP);
  return (
    <AppBar>
      <Toolbar
        disableGutters
        sx={{
          height: {
            xs: HEADER.H_MOBILE,
            md: HEADER.H_DESKTOP,
          },
          transition: theme.transitions.create(['height'], {
            easing: theme.transitions.easing.easeInOut,
            duration: theme.transitions.duration.shorter,
          }),
          ...(offsetTop && {
            ...bgBlur({
              color: theme.palette.background.default,
            }),
            height: {
              md: HEADER.H_DESKTOP_OFFSET,
            },
          }),
        }}
      >
        <Container sx={{ height: 1, display: 'flex', alignItems: 'center' }}>
          <Badge
            sx={{
              [`& .${badgeClasses.badge}`]: {
                top: 8,
                right: -16,
              },
            }}
          >
            <Logo />
          </Badge>

          <Box sx={{ flexGrow: 1 }} />

          {mdUp && <NavDesktop data={navConfig} />}

          <Stack alignItems="center" direction={{ xs: 'row', md: 'row-reverse' }}>
            {/* {
              user ? (
                <Button variant="contained"
                  target="_blank"
                  rel="noopener"
                  onClick={logout}
                >
                  Log out
                </Button>

              ) : (

              )

            } */}
            <Button variant="contained"
              // target="_blank"
              rel="noopener"
              href="#waitlist"
            >
              Join Waitlist Now
            </Button>

            {/* {mdUp && (
              user ?
                <Button
                  variant="outlined"
                  rel="noopener"
                  color={theme.palette.mode === 'light' ? 'secondary' : 'primary'}
                  sx={{ mr: 1 }}
                >
                  Hello {user.displayName}
                </Button>
                :

                <LoginButton />
            )} */}

            {/* <SettingsButton
              sx={{
                ml: { xs: 1, md: 0 },
                mr: { md: 2 },
              }}
            /> */}

            {!mdUp && <NavMobile data={navConfig} />}
          </Stack>
        </Container>
      </Toolbar>

      {offsetTop && <HeaderShadow />}
    </AppBar>
  );
}
