import PropTypes from 'prop-types';
import { forwardRef } from 'react';

import Link from '@mui/material/Link';
import { Box, Typography } from "@mui/material";
import { useTheme } from '@mui/material/styles';

import { RouterLink } from 'src/routes/components';

// ----------------------------------------------------------------------

const Logo = forwardRef(({ disabledLink = false, showText = true, sx, ...other }, ref) => {
  const theme = useTheme();

  // check if light theme 
  // if light theme, use logo with dark background
  // if dark theme, use logo with light background


  // OR using local (public folder)
  // -------------------------------------------------------
  const logo = (
    // <Box sx={{
    //   display: 'flex',
    //   alignItems: 'center',
    //   justifyContent: 'center',
    //   ...sx

    // }}>

    <Box
      component="img"
      alt="logo"
      src="logo/logo_single.png"
      sx={{
        zIndex: 1, width: 42, height: 42,
        borderRadius: 1,

        cursor: 'pointer', ...sx
      }}
    />
    /* <Box
      component="img"
      alt="logo"
      src={theme.palette.mode === 'light' ? 'logo/7.svg' : 'assets/images/favicon-white.png'}
      sx={{ ml: 0, width: 60, height: 60, cursor: 'pointer', ...sx }}
    /
  </Box> */
  );

  if (disabledLink) {
    return logo;
  }

  return (
    <Link component={RouterLink} href="/" sx={{ display: 'flex', alignItems: "center" }}>
      {logo}
      {showText && (
        <Typography variant="h5" sx={{
          color: theme.palette.mode === 'light' ? 'text.primary' : 'text.secondary',
          ml: 1,
          //  mt: 1,
        }}>
          Voolist
        </Typography>
      )}
    </Link>
  );
});

Logo.propTypes = {
  disabledLink: PropTypes.bool,
  sx: PropTypes.object,
  showText: PropTypes.bool
};

export default Logo;
